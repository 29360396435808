import { EnvironmentProviders, Provider } from '@angular/core';
import { forceBackToCurrentLocation } from '@bcf-v2-configurators/tokens/cashier/mobile-web-twa/force-back-to-current-location';
import { FORCE_BACK_TO_CURRENT_LOCATION } from '@bcf-v2-configurators/tokens/cashier/token';
import { commonTaxPayoutDetailsInfo } from '@bcf-v2-configurators/tokens/my-bets/letsbetmd/common-tax-payout-details-info';
import { customTaxPayoutDetailsInfo } from '@bcf-v2-configurators/tokens/my-bets/letsbetmd/custom-tax-payout-details-info';
import {
  COMMISSION_ENABLED,
  COMMON_TAX_PAYOUT_DETAIL_INFO,
  CUSTOM_TAX_PAYOUT_DETAIL_INFO
} from '@bcf-v2-configurators/tokens/my-bets/token';

export const settingsProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: FORCE_BACK_TO_CURRENT_LOCATION,
    useValue: forceBackToCurrentLocation
  },
  {
    provide: COMMISSION_ENABLED,
    useValue: false
  },
  {
    provide: COMMON_TAX_PAYOUT_DETAIL_INFO,
    useValue: commonTaxPayoutDetailsInfo
  },
  {
    provide: CUSTOM_TAX_PAYOUT_DETAIL_INFO,
    useValue: customTaxPayoutDetailsInfo
  }
];
