import { InjectionToken } from '@angular/core';
import { TimeFormatOptions } from '@bcf-v2-ui-shared/misc/date-utils/get-simplified-format-relative-locale';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('dateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FULL_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('fullDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SUFFIX_TIME_FORMAT: InjectionToken<TimeFormatOptions> = new InjectionToken<TimeFormatOptions>(
  'suffixTimeFormat'
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TIME_FORMAT: InjectionToken<string> = new InjectionToken<string>('timeFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PRE_MATCHES_GROUP_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>(
  'preMatchesGroupDateFormat'
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const USER_PANEL_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('userPanelDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const USER_PANEL_TIME_FORMAT: InjectionToken<string> = new InjectionToken<string>('userPanelDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const USER_PANEL_DATE_TIME_FORMAT: InjectionToken<string> = new InjectionToken<string>('userPanelDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RESULT_CENTER_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('resultCenterDateFormat');
