import { InjectionToken } from '@angular/core';
import { PossibleWinningAboveWinTreshold } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const POSSIBLE_WINNING_ABOVE_WIN_TRESHOLD_MODE: InjectionToken<PossibleWinningAboveWinTreshold> =
  new InjectionToken<PossibleWinningAboveWinTreshold>('possibleWinningAboveWinTresholdMode', {
    factory: () => undefined
  });

// eslint-disable-next-line @typescript-eslint/naming-convention
export const POSSIBLE_WINNING_MAX: InjectionToken<number> = new InjectionToken<number>('possibleWinningMax', {
  factory: () => Infinity
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PAYOUT_DETAILS_ENABLED: InjectionToken<boolean> = new InjectionToken<boolean>('payoutDetailsEnabled', {
  factory: () => true
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BETSLIP_SUGGESTED_STAKES: InjectionToken<number[]> = new InjectionToken<number[]>(
  'ebetslipSuggestedStakes',
  {
    factory: () => []
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUICK_BLIK_IN_BETSLIP_ENABLED: InjectionToken<boolean> = new InjectionToken<boolean>(
  'quickBlikInBetslipEnabled',
  {
    factory: () => false
  }
);
