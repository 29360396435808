import { NgComponentOutlet } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IconsGcpLoader } from '@bcf-icons/svg/common';
import { identifier } from '@bcf-icons/svg/packages/icon-package-sb-letsbetmd-mobile-web';
import type { PushNotificationsTwaComponent } from '@bcf-libs/ui/others/components/sportsbook/lvbetpl/mobile-web/src/lib/push-notifications/push-notifications-twa.component';

@Component({
  standalone: true,
  selector: 'bcf-root',
  imports: [RouterOutlet, NgComponentOutlet],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public pushNotificationsTwaComponent: typeof PushNotificationsTwaComponent | undefined;

  constructor(
    private _injector: Injector,
    private _iconsGcpLoader: IconsGcpLoader
  ) {}

  public ngOnInit(): void {
    this._initApp();
    this._initIcons();
    this._initPushNotifications();
  }

  private async _initApp(): Promise<void> {
    this._injector
      .get(
        (
          await import(
            /* webpackChunkName: "app-initializator" */ '@apps/projects/sportsbook/letsbetmd/mobile-web/src/app/app-initializator'
          )
        ).LazyInitializatorSbLetsbetmdMobileWeb
      )
      .init();
  }

  private async _initIcons(): Promise<void> {
    this._iconsGcpLoader.init(identifier);
  }

  private async _initPushNotifications(): Promise<void> {
    this.pushNotificationsTwaComponent = await import(
      /* webpackChunkName: "push-notifications-initializer" */ '@bcf-libs/ui/others/components/sportsbook/lvbetpl/mobile-web/src/lib/push-notifications/push-notifications-twa.component'
      // eslint-disable-next-line @typescript-eslint/typedef
    ).then((mod) => mod.PushNotificationsTwaComponent);
  }
}
